
/* #destaques .card{
    position: relative;
  overflow: hidden;
  width: 280px;
}
#destaques .card img{
    border-radius: 20px 20px 0px 0px;
    max-width: 100%;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
#destaques .card:hover img{
    -moz-transform: scale(1.2);
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
}
#destaques .card{
    box-shadow: rgba(0, 0, 0, 0.329) 1px 2px 15px;
    border-radius: 20px;
}
#destaques .card:hover{
    box-shadow: #00000094 3px 5px 20px;
    border: #fff solid 1px;
}

#destaques .card .icon{
    display: flex;
    margin: 0 auto;
}
#destaques .card ul{
    list-style-type: none;
    margin-left: -29px;
}
#destaques .card svg{
    margin-left: 10px;
}
#destaques .card span{
    margin-left: 5px;
}

#destaques .address span{
font-size: 15px;
font-weight: 200;
}
#destaques .card-valor{
    background-color:#d09d2c ;
    padding-top: 15px;
    height: 60px;
    border-radius: 0px 0px 20px 20px;
}
#destaques .valor{
    font-size: 18px;
    color:#000;
    margin-right: 10px;
}
#destaques .description{
    color:#05579e ;
    height: 100px;
}

#destaques .badge{
    margin-top: 10px;
    background-color: #05579e;
} */

.swiper {
    width: 100%;
  }

#destaques .swiper-slide img{
  width: auto;
  height: 60vh;
}
/*--------------------------------------------------------------
# Card General
--------------------------------------------------------------*/
@media (max-width: 767px) {

    .card-box-a {
      margin-bottom: 2.5rem;
    }
  }
  
  .card-box-a span{
    line-height: 0;
  }
  
  @media (min-width: 768px) {
  
    .grid .card-box-a{
      margin-bottom: 2.5rem;
    }
  }
  
  .card-box-a,
  .card-box-b,
  .card-box-d {
    position: relative;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
 
  .card-box-a .img-a,
  .card-box-a .img-b,
  .card-box-b .img-a,
  .card-box-b .img-b {
    transition: 0.8s all ease-in-out;

  }
  
  @media (min-width: 768px) {
  
    .card-box-a:hover .img-a,
    .card-box-a:hover .img-b,
    .card-box-b:hover .img-a,
    .card-box-b:hover .img-b {
      transform: scale(1.2);

    }
  }
  
  @media (min-width: 768px) {
  
    .card-box-a .price-a,
    .card-box-b .price-a {
      font-size: 0.9rem;
    }
  }
  
  @media (min-width: 992px) {
  
    .card-box-a .price-a,
    .card-box-b .price-a {
      font-size: 1rem;
    }
  }
  
  .card-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  
  .card-shadow {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  
  .card-shadow:before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 27%, rgba(0, 0, 0, 0.514) 90%);
  }
  
  /*======================================
  //--//-->   PROPERTY - CARD-A
  ======================================*/
  @media (min-width: 768px) {
    .card-box-a:hover .card-overlay-a-content {
      bottom: 60px;
    }
  }
  
  @media (min-width: 768px) and (min-width: 768px) {
    .card-box-a:hover .card-overlay-a-content {
      bottom: 60px;
    }
  }
  
  @media (min-width: 768px) and (min-width: 992px) {
    .card-box-a:hover .card-overlay-a-content {
      bottom: 64px;
    }
  }
  
  @media (min-width: 768px) {
    .card-box-a:hover .card-body-a {
      padding-bottom: 1rem;
    }
  }
  
  @media (max-width: 767px) {
    .card-box-a .card-overlay-a-content {
      bottom: 65px;
    }
  
    .card-box-a .card-body-a {
      padding-bottom: 1rem;
    }
  }
  
  .card-overlay-a-content {
    transition: all 0.5s ease-in;
    position: absolute;
    width: 100%;
    bottom: 0px;
    z-index: 2;
  }
  
  @media (min-width: 768px) {
    .card-overlay-a-content {
      bottom: -20px;
    }
  }
  
  @media (min-width: 992px) {
    .card-overlay-a-content {
      bottom: 0px;
    }
  }
  
  .card-header-a {
    padding: 0 1rem;
  }
  
  .card-header-a .card-title-a {
    color: #ffffff;
    margin-bottom: 0;
    padding-bottom: 0.7rem;
  }
  
  @media (min-width: 768px) {
    .card-header-a .card-title-a {
      font-size: 1.3rem;
    }
  }
  
  @media (min-width: 992px) {
    .card-header-a .card-title-a {
      font-size: 1.3rem;
    }
  }
  
  .card-header-a .card-title-a a {
    color: #ffffff;
    text-decoration: none;
  }
  
  .card-body-a {
    z-index: 2;
    transition: all 0.5s ease-in;
    padding: 0rem 1rem 2rem 1rem;
  }
  
  .card-body-a .price-box {
    padding-bottom: 0.5rem;
  }
  
  .price-a {
    color: #ffffff;
    padding: 0.6rem 0.8rem;
    border: 2px solid #ffc107;
    border-radius: 50px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }
  
  .card-footer-a {
    width: 100%;
    position: absolute;
    z-index: 2;
    background-color: #ffc107;
  }
  
  .card-info {
    list-style: none;
    margin-bottom: 0;
    padding: 0.5rem 0;
  }
  
  .card-info .card-info-title {
    font-size: 1rem;
    color: #313131;
  }
  
  @media (min-width: 768px) {
    .card-info .card-info-title {
      font-size: 0.9rem;
    }
  }
  
  @media (min-width: 992px) {
    .card-info .card-info-title {
      font-size: 1rem;
    }
  }
  
  .card-info li span {
    color: #ffffff;
    font-size: 0.9rem;
  }
  
  @media (min-width: 768px) {
    .card-info li span {
      font-size: 0.8rem;
    }
  }
  
  @media (min-width: 992px) {
    .card-info li span {
      font-size: 1rem;
    }
  }
  
  /*------/ Property Grid /-----*/
  .property-grid .grid-option {
    text-align: right;
    margin-bottom: 2rem;
  }
  
  .property-grid .grid-option .custom-select {
    border-radius: 0;
    width: 200px;
    border-color: #313131;
  }
  
  .property-grid .grid-option .custom-select:hover,
  .property-grid .grid-option .custom-select:active,
  .property-grid .grid-option .custom-select:focus {
    border-color: #313131;
  }
  
  .property-grid .grid-option .custom-select option {
    border-color: #313131;
  }
  