/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
    background: rgb(0, 96, 166);
    background: linear-gradient(45deg, rgb(0, 59,106), rgb(0, 96, 166) 54%);
    /* background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.9)), url("https://dlzumwzvsvpdqblhfoof.supabase.co/storage/v1/object/public/jofran/0776acd6d6bb5c1d9d2829f0.png") fixed center center;
    background-size: cover; */
    padding: 120px 0;
  }
  
  .cta h3 {
    color: #fff;
    font-size: 28px;
    font-weight: 700;
  }
  
  .cta p {
    color: #fff;
  }
  
  .cta .cta-btn {
    font-family: "Raleway", sans-serif;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 30px;
    border-radius: 2px;
    transition: 0.5s;
    margin: 10px;
    background: #e8b00d;
    color: #000;
    border-radius: 50px;
  }
  
  .cta .cta-btn:hover {
    background: #cc881d;
  }
  
  @media (max-width: 1024px) {
    .cta {
      background-attachment: scroll;
    }
  }
  
  @media (min-width: 769px) {
    .cta .cta-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
  