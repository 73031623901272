.mySwiper .swiper {
    width: 100%;
    height: 100%;
  }
 
.mySwiper .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .mySwiper .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
  
  #sobre .swiper {
    margin-left: auto;
    margin-right: auto;

  }
  .swiper-button-prev,
  .swiper-button-next{
    color: #dea113;
  }
  .swiper-pagination-bullet-active{
   background-color: #dea113;

  }
  .swiper-pagination-bullet{
    width: 15px;
    height: 15px;
    color: #fff;
  }
