.perfil {
  width: 15rem;
}

#main-banner {
 background: rgb(255,255,255);
background: linear-gradient(80deg, rgba(255,255,255,1) 51%, rgba(0, 92, 168, 0.53) 100%);
}

#main-banner span {
  background:linear-gradient(80deg, #cb9500 0%, #e3ca15 90%);
}

.banner2 {
  width: 100%;
  height: 100vh;
  background: url("https://dlzumwzvsvpdqblhfoof.supabase.co/storage/v1/object/public/jofran/banner/banner%20jofran%20imoveis%20site%20principal.png?t=2024-05-02T19%3A40%3A39.743Z") top center;
  background-size: cover;
  position: relative;
  z-index: 99;

}

/* .banner2:before {
  content: "";
  background: rgba(19, 53, 80, 0.562);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
} */

.banner2 .container {
  position: relative;
  text-align: center;
}

.banner2 h1 {
  margin: 0 0 10px 0;
  text-shadow: #000 2px 2px;
  font-size: 48px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
}

.banner2 h2 {
  color: #ffffff;
  text-shadow: #00192a 2px 2px;
  font-weight: 600;
  margin-bottom: 40px;
  font-size: 24px;

}
.banner2 a{
  background-color: #1e73be;
  box-shadow: #00192a 2px 3px 5px;
  padding: 10px;
  font-weight: 600;
  font-size: 24px;
  color: #eee;
}
.banner2 a:hover{
  background-color: #00192a;
  color: #eee;
}



@media (min-width: 1024px) {
  .banner2 {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  .banner2 h1 {
    font-size: 28px;
    line-height: 36px;
  }

  .banner2 h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }

}