 /*--------------------------------------------------------------
# F.A.Q Section
--------------------------------------------------------------*/
@media (max-width: 991px) {
    .faq {
      padding: 0;
    }
  }
  
  .faq .content h3 {
    font-weight: 400;
    font-size: 2.5em;
    color: #000;
  }
  
  
  
  .faq .content p {
    font-size: 15px;
    color: #717070;
  }
  
  .faq .img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 400px;
  }
  
  .faq .img-fluid{
    border-radius: 5%;
  }
  
  .faq{
    margin: 30px;
  }
  
  .faq .accordion-item {
    border: 0;
    margin-top: 15px;
    box-shadow: 0px 5px 5px 0px rgba(76, 76, 76, 0.3);
  }
  
  
  
  .faq .accordion-button {
    padding: 15px 40px 20px 60px;
    color: #ffffff;
    text-align: left;
    background: rgb(0, 96, 166);
    background: linear-gradient(45deg, rgb(0, 59,106), rgb(0, 96, 166) 54%);
  
    box-shadow: none;
  }
  .faq .accordion-button h4{
    font-size: 1.1em;
    font-weight: 600;
  }
  
  
  .faq .accordion-body {
    padding: 0 30px 15px 60px;
    border: 0;
    border-radius: 5px;
    background: #fff;
    box-shadow: none;
  } 

  .faq .accordion-body a{
    color: #dea113;
    border-bottom: #dea113 solid 1px;
  }