#recrutamento .bg-max {
    background: rgb(0, 67, 168);
    background: linear-gradient(45deg, rgb(22, 104, 251), rgb(0, 67, 168) 54%);
    background-color: #fff;
    max-width: 480px;
    height: 100%;
    margin: 0 auto;
}

#recrutamento .btn-success{
    border-radius: 0;
    background-color: #6ab60f;
    color: #fff;
  }