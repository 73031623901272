/*--------------------------------------------------------------
# Features
--------------------------------------------------------------*/

.features .icon-box h4 {
    font-size: 20px;
    font-weight: 700;
    margin: 5px 0 10px 60px;
  }
  
  .features .icon-box i {
    font-size: 48px;
    float: left;
    color: #e8b00d;
  }
  
  .features .icon-box p {
    font-size: 15px;
    color: #848484;
    margin-left: 60px;
  }
  
  .features .image {
    background-image: url("https://dlzumwzvsvpdqblhfoof.supabase.co/storage/v1/object/public/jofran/financiamento-seguro-amarelo.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 400px;
  
  }
  