 /*--------------------------------------------------------------
  # Icon Boxes
  --------------------------------------------------------------*/
  .icon-boxes {
    padding-top: 0;
    position: relative;
    z-index: 100;
    margin-top: -150px;
  }
  
  .icon-boxes .icon-box {
    padding: 40px 30px;
    position: relative;
    overflow: hidden;
    background: #fff;
    box-shadow: 5px 10px 29px 0 rgba(68, 88, 144, 0.2);
    transition: all 0.3s ease-in-out;
    border-radius: 10px;
  }
  .icon-box .description {
    height: 85px;
  }
  .icon-boxes .icon {
    margin: 0 auto 20px auto;
    display: inline-block;
    text-align: center;

  }
  
  .icon-boxes .icon i {
    font-size: 36px;
    line-height: 1;
    color:#cb9500;
  }
  
  .icon-boxes .title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
    color: #05579e;
  }
  
  .icon-boxes  a{
    background-color: #cb9500;
    padding: 10px;
    font-weight: 600;
    font-size: 24px;
    color: #eee;
  }
  .icon-boxes  a:hover{
    background-color: #d09d2c;
    color: #eee;
  }
  

  .icon-boxes .description {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 0;
    color: #777777;
  }