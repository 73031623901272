#heromodal video{
width: 100%;
height: 80vh;
margin: 0;
}
.modal-dialog{
    height: 70vh;
}
#heromodal .modal-content{
    background-color: #00000000;
    border: none;
}
#heromodal .modal-header{
    border-bottom: none;
}
#heromodal .modal-content .btn-close{
    color: #fff;
    background-color: #fff;
}

.fade{
    background-color: #000000a6;
}