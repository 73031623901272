/*--------------------------------------------------------------
  # Top Bar
  --------------------------------------------------------------*/
  #topbar {
    background: #fff;
    font-size: 15px;
    height: 100px;
    padding: 0;
    color: #043c7c;
  }
  #topbar img{
    max-width: 80px; 
    margin-right: 80px;
  }
  #topbar p{
    width: 200px;
  }

  #topbar .contact-info a {
    line-height: 0;
    color: #043c7c;
    transition: 0.3s;
  }
  
  #topbar .contact-info a:hover {
    color: #001725;
  }
  
  #topbar .contact-info i {
    color: #043c7c;
    line-height: 20px;
    margin-right: 5px;
    width: 20px;
  }
  
  #topbar .contact-info .phone-icon {
    margin-left: 15px;
  }
  
  #topbar .social-links a {
    color: rgba(255, 255, 255, 0.6);
    padding: 4px 12px;
    display: inline-block;
    line-height: 32px;
    transition: 0.3s;
    width: 40px;
  }
  
  #topbar .social-links a:hover {
    color: #fff;
  }
   /*--------------------------------------------------------------
  # logo
  --------------------------------------------------------------*/
  .logo-img{
    height: 6.0rem;
  }
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    background: #1e73be;
    transition: all 0.5s;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 997;
    height:50px;
    box-shadow: #000 1px 3px 7px;
  }
  
  #header.fixed-top {
    background: #28658d;
  }
  
  
  
  #header .offcanvas-body .nav-link{
    color: #fff;
    font-weight: 800;
    text-decoration: none;
    text-transform:uppercase;
    margin: 0px 20px 20px 20px;
  }
  #header .offcanvas-body .nav-link:hover{
    color: #ffc107;
  }
 

  