/*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footerHome {
    background: #001725;
    padding: 0 0 30px 0;
    color: #fff;
    font-size: 14px;
  }
  .maps{
    margin-top: 0;
    width: 100%; 
    height: 350px;
  } 
  
  
  #footerHome .footer-top {
    padding: 60px 0 30px 0;
  }
  
  #footerHome .footer-top .footer-info {
    margin-bottom: 15px;
    background: #1e73be;
    color: #fff;
    border-top: 10px solid #d09d2c;
    text-align: center;
    padding: 30px 20px;
  }
  
  #footerHome .footer-top .footer-info h3 {
    font-size: 36px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
  }
  
  #footerHome .footer-top .footer-info p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
  }
  
  #footerHome .footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #262626;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  
  #footerHome .footer-top .social-links a:hover {
    background: #d09d2c;
    color: #fff;
    text-decoration: none;
  }
  
  #footerHome .footer-top h4 {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    position: relative;
    padding-bottom: 12px;
  }
  
  #footerHome .footer-top .footer-links {
    margin-bottom: 30px;
  }
  
  #footerHome .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  #footerHome .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #ed5b5b;
    font-size: 18px;
    line-height: 1;
  }
  
  #footerHome .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  
  #footerHome .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  
  #footerHome .footer-top .footer-links ul a {
    color: #fff;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  
  #footerHome .footer-top .footer-links ul a:hover {
    color: #d09d2c;
  }
  
  #footerHome .footer-top .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 4;
  }
  
  #footerHome .footer-top .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px;
    width: calc(100% - 110px);
  }
  
  #footerHome .footer-top .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #cc1616;
    color: #fff;
    transition: 0.3s;
    border-radius: 4;
  }
  
  #footerHome .footer-top .footer-newsletter form input[type=submit]:hover {
    background: #e82d2d;
  }
  
  #footerHome .copyright {
    border-top: 1px solid #262626;
    text-align: center;
    padding-top: 30px;
  }
  
  #footerHome .credits {
    padding-top: 5px;
    text-align: center;
    font-size: 13px;
    color: #fff;
  }
  
  #footerHome .credits a {
    color: #d09d2c;
  }
  
  @media (max-width: 575px) {
    #footerHome .footer-top .footer-info {
      margin: -20px 0 30px 0;
    }
  }

  .localization{
    border:0; width: 100%; height: 384px;
  }